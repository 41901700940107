import React from 'react'

import Layout from '../../components/layout';
import SEO from '../../components/SEO';

const AboutPage = () => (
    <Layout>
        <SEO title="About" description="About PSE Lookup"/>
        <div className="h-full overflow-y-auto bg-gray-100">
            <div className="flex flex-col md:flex-row">
                <div className="md:w-3/5">
                    <div className="md:p-6">
                        <div className="bg-white px-4 py-5 border-b border-gray-200">
                            <div className="m-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    PSE Lookup is a personal project and not affiliated with the Philippine Stock
                                    Exchange.
                                </h3>
                            </div>
                            <p className="m-4 text-sm leading-5 text-gray-500">
                                The goal of this tool is to make downloading of Philippine Stock Exchange historical
                                data
                                readily
                                available to the public.
                                A project created out of frustration when searching for historical data myself.
                            </p>
                            <p className="m-4 text-sm leading-5 text-gray-500">
                                Please counter check the data you download as I can&#39;t guarantee for its
                                accurateness. If
                                you
                                ever
                                find
                                something erroneous,
                                please don&#39;t hesitate to message me at <a
                                href="mailto:vrymel@gmail.com">vrymel@gmail.com</a> so I
                                can
                                address it.
                            </p>
                            <p className="m-4 text-sm leading-5 text-gray-500">
                                Lastly, you agree that PSE Lookup is not liable for any losses that may result from any
                                data
                                you
                                downloaded
                                from the tool.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="md:w-2/5">
                    <div className="mt-2 md:mt-0 md:p-6">
                        <div className="bg-white px-4 py-5 border-b border-gray-200">
                            <div className="m-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    ☕ &nbsp;Buy me a coffee?
                                </h3>
                            </div>
                            <p className="m-4 text-sm leading-5 text-gray-500">
                                Keep me awake and motivated by buying me a coffee. On top of that, any support you give
                                will
                                lighten the maintenance cost of this project.
                            </p>
                            <p className="m-4 text-sm leading-5 text-gray-500">Here&#39;s my GCash QR Code 😇</p>
                            <img src="/images/GCASH-QR-Vrymel-4002.png" className="w-48 md:w-64 ml-4"
                                 alt="GCash QR Code"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default AboutPage
